
import { defineComponent } from "vue";

//import Swal from "sweetalert2/dist/sweetalert2.min.js";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";

export default defineComponent({
  name: "new-entity-course",
  components: {
    Datatable,
  },
  data() {
    return {
      batch: {},
      employee: {},
      details: {},
      selected: {},
      weekplan: {},
      entityTypes: [],
      industry: [],
      entityInfos: [],
      divisions: [],
      districts: [],
      subDistricts: [],
      empDistricts: [],
      selectedEntity: [],
      selectedOrganizationEntity: [],
      selectedTrainingTypes: [],
      selectedTrainingName: [],
      selectedTrainingTrade: [],
      selectedTrainingInstitutes: [],
      selectedDesignation: [],
      selectedGender: [],
      trainingName: [
        {
          id: 1,
          name: "Management in Office",
        },
        {
          id: 2,
          name: "Iot in Office",
        },
      ],
      trainingTrade: [
        {
          id: 1,
          name: "Management in Office",
        },
        {
          id: 2,
          name: "Iot in Office",
        },
      ],
      trainingTypes: [
        {
          id: 1,
          name: "IoT Skills",
        },
        {
          id: 2,
          name: "Management",
        },
      ],
      tableData: [
        {
          id: 1,
          short_name: "ABC",
          name: "A.B.C Bangladesh-Turkish Technical School",
          address: "Nazirhat Municipality ,Fatickchari ",
          phone: "0312552697 ",
          mail: "abcbdtktschool@yahoo.com",
          website: "www.doulatpurabchighschool.edu.bd ",
        },
        {
          id: 2,
          short_name: "ACA",
          name: "ACA Technical Institute ",
          address: "Faterpur, Enayetnagar, Matlab Uttor, Chandpur ",
          phone: "0312510677  ",
          mail: "	aca.seip@gmail.com ",
          website: "www.aca.org ",
        },
      ],
      tableHeader: [
        {
          name: "Sl",
          key: "sl",
          sortable: true,
          width: "5px",
          selected: true,
        },
        {
          name: "Logo",
          key: "logo",
          sortable: true,
        },
        {
          name: "Type of Visitors",
          key: "Type_of_visitors",
          sortable: true,
          selected: true,
        },
        {
          name: "Resource Name",
          key: "resource_name",
          sortable: true,
          selected: true,
        },
        {
          name: "Date of Visit",
          key: "date_of_visit",
          sortable: true,
          selected: true,
        },
        {
          name: "Name of Entity",
          key: "name_of_entity",
          sortable: true,
          selected: true,
        },
        {
          name: "Name of Organization",
          key: "organization_name",
          sortable: true,
          selected: true,
        },
        {
          name: " Area of Expertise",
          key: "area_expertise",
          sortable: true,
          selected: true,
        },
        {
          name: " Mapped Course",
          key: "mapped_course",
          sortable: true,
          selected: true,
        },
        {
          name: " Pedagogoy Local",
          key: "pedagogy_local",
          sortable: true,
          selected: true,
        },
        {
          name: " Pedagogoy Overseas",
          key: "pedagogy_overseas",
          sortable: true,
          selected: true,
        },
        {
          name: " Pedagogoy Local Trade",
          key: "pedagogy_local_trade",
          sortable: true,
          selected: true,
        },
        {
          name: " Pedagogoy Overseas Trade",
          key: "pedagogy_oversease_trade",
          sortable: true,
          selected: true,
        },
        {
          name: "Address of Institute",
          key: "address_of_institute",
          sortable: true,
          selected: true,
        },
        {
          name: "Date of Report Submission",
          key: "date_of_report_submission",
          sortable: true,
          selected: true,
        },
        {
          name: "Email",
          key: "mail",
          sortable: true,
        },
        {
          name: "Website",
          key: "website",
          sortable: true,
        },
        {
          name: "Assessment Date",
          key: "assessment_date",
          sortable: true,
        },
        {
          name: "Assessment Info",
          key: "assessment_info",
          sortable: true,
        },
      ],
      organizationEntity: [
        {
          id: "1",
          name: "ZXR",
        },
        {
          id: "2",
          name: "YLD",
        },
        {
          id: "3",
          name: "KHL",
        },
        {
          id: "4",
          name: "FGK",
        },
      ],
      batchInfos: [
        {
          id: "1",
          name: "Batch-1",
        },
        {
          id: "2",
          name: "Batch-2",
        },
        {
          id: "3",
          name: "Batch-3",
        },
        {
          id: "4",
          name: "Batch-4",
        },
      ],
      courseTypes: [
        {
          id: "1",
          name: "New Entrant",
        },
        {
          id: "2",
          name: "Up Skill",
        },
        {
          id: "3",
          name: "Up Skill - Special (70-30)",
        },
        {
          id: "4",
          name: "New Entrant - Special (70-30)",
        },
      ],
      courseInfos: [
        {
          id: 1,
          name: "Welding & Fabrication (NE) ",
          code: "W&F (NE)",
          type: "New Entrant",
          Mgt: "Yes ",
          month: "3",
          trance: "TR-1 ",
          unit_cost: "43413",
        },
        {
          id: 2,
          name: "Electrical & Navigation Equipment Installation (NE) ",
          code: "E&N (NE) ",
          type: "New Entrant",
          Mgt: "Yes",
          month: "3",
          trance: "TR-1 ",
          unit_cost: "31674",
        },
      ],
      genderData: [
        {
          id: 1,
          name: "male",
        },
        {
          id: 2,
          name: "female",
        },
      ],
      designationData: [
        {
          id: 1,
          name: "Associate Instructor",
        },
        {
          id: 2,
          name: "Assistant Engineer",
        },
        {
          id: 3,
          name: "Computer Teacher",
        },
        {
          id: 4,
          name: "Chief Instructor",
        },
        {
          id: 5,
          name: "Chief Trainer",
        },
      ],
      trainingInstitutes: [
        {
          id: 1,
          name: "XYZ",
        },
        {
          id: 2,
          name: "BGD",
        },
        {
          id: 3,
          name: "YUK",
        },
        {
          id: 4,
          name: "ASD",
        },
        {
          id: 5,
          name: "YUJ",
        },
      ],
      loading: false,
      courseInfoData: false,
      showCourseNotice: false,
      showtrainingProviderNotice: false,
    };
  },
  async created() {
    await this.getEntityTypes();
    await this.getEntityInfos();
  },
  methods: {
    async formSubmit() {
      //form submit
    },
    async getEntityInfos() {
      await ApiService.get("entity/infos")
        .then((response) => {
          this.entityInfos = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    async getEntityTypes() {
      await ApiService.get("entity/types")
        .then((response) => {
          this.entityTypes = response.data;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    resetData() {
      this.selectedEntity = [];
      this.selectedOrganizationEntity = [];
      this.selectedTrainingTypes = [];
      this.selectedTrainingName = [];
      this.selectedTrainingTrade = [];
      this.selectedTrainingInstitutes = [];
      this.selectedDesignation = [];
      this.selectedGender = [];
      this.batch = {};
    },
    courseInfo() {
      this.courseInfoData = true;
    },
    headerFilter() {
      this.showCourseNotice = true;
    },
    trainingProviderNotice() {
      this.showtrainingProviderNotice = true;
    },

    selectedTableHeader() {
      const st = [] as any;
      this.tableHeader.map((item) => {
        if (item.selected) {
          st.push({ name: item.name, key: item.key });
        } else {
        }
      });
      return st;
    },
  },

  beforeMount() {
    this.selectedTableHeader();
  },
  computed: {},
  setup() {
    const AssociationSchema = Yup.object().shape({
      email: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      web_url: Yup.string().required().label("Web Url"),
      postcode: Yup.string().required().max(4).label("Postcode"),
      registration_number: Yup.string().required().label("Registration Number"),
      registration_authority: Yup.string()
        .required()
        .label("Registration Authority"),
      telephone: Yup.number().required().min(11).label("Telephone"),
      entity_name: Yup.string().required().label("Name"),
      entity_short_name: Yup.string().required().label("Short Name"),

      employeeEmail: Yup.string()
        .email("Must be valid email")
        .required()
        .label("Email"),
      //presentDistrict: Yup.string().required().label("Present District"),
      name: Yup.string().required().label("Name"),
      designation: Yup.string().required().label("Designation"),
      gender: Yup.string().required().label("Gender"),
      mobile: Yup.number().required().min(11).label("Mobile"),
      dob: Yup.string().required().label("Date of Birth"),

      password: Yup.string()
        .required()
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .label("Password"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Password didn't match!")
        .label("Confirm Password"),
    });
    return {
      AssociationSchema,
    };
  },
});
